import { Action } from '@gamepark/rules-api'
import { AnimationState, AnimationStep } from '../animations'

export type DisplayedAction<Move = any, PlayerId = any> = Action<Move, PlayerId> & {
  played: number // Number of move + consequences already played in the game state
  pending?: boolean // True if move was sent by the player but the notifications was not received yet
  delayed?: boolean // True if move will be executed later
  animation?: AnimationState // Track if an animation is ongoing (and whether the animated move is executed of not yet)
  cancelled?: boolean // True if move must be undone
  cancelPending?: boolean // True if move has been undone but the notification confirming it is not received yet
  local?: boolean // True if move is not sent to server
  activePlayers?: PlayerId[] // List of players that can play when this action starts
  transient?: boolean // True if action must not be kept in the actions list once the animation is complete
}

export function isActionToAnimate(action: DisplayedAction): boolean {
  if (action.delayed) return false
  if (action.cancelled) return action.played > 0
  return action.played <= action.consequences.length
}

export function getAnimatedMove<Move>(action: DisplayedAction<Move>, step = action.animation?.step): Move {
  const consequenceIndex = (step === AnimationStep.BEFORE_MOVE || step === AnimationStep.AFTER_UNDO) ? action.played - 1 : action.played - 2
  return consequenceIndex < 0 ? action.move : action.consequences[consequenceIndex]
}
